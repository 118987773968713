@use '../../../theme' as *;

.scm-loader-container{
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .scm-loader{
        $size: 48px;
        width : $size;
        height: $size;
        border: 5px solid $theme-black;
        border-bottom-color: $theme-color;
        border-radius: 50%;
        display: inline-block;
        animation: rotation 1s linear infinite;
    }
}
@keyframes rotation {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
}
