@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko&display=swap');

$theme-max-width: 1400px;
$theme-transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);

$theme-color: #a09600;
$theme-white: rgb(255,255,255);
$theme-grey: #dadce1;
$theme-black: rgb(0,0,0);


/* Social Media Colors */
$twitter-blue: rgb(29, 155, 240);
$linkedin-blue: #0072b1;
$facebook-blue: rgb(66, 103, 178);
$youtube-red: rgb(255,0,0);



