/* Menu Component Styles */
@use '../../theme' as *;

#current-page{
    color: $theme-color;

    a{
        color: $theme-color;
    }
}

