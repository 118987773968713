@use './theme' as *;

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    color: #fff;
    text-decoration: none;
    transition: $theme-transition;
}

body{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $theme-grey;
    width: 100%;
    margin: 0;
    font-family: Poppins, sans-serif;
}

body::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
    background: black;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
    background-color: #a39a01;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid #a39a01;  /* creates padding around scroll thumb */
}

#root{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
}

.content-container{
    width: 95%;
    margin: auto;
    max-width: $theme-max-width;
    overflow: hidden;
}

.page-section{
    width: 95%;
    max-width: $theme-max-width;
    padding: 2rem 0;
    margin: auto;
}

.page-heading{
    font-family: Teko, arial;
    font-size: 50px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    color: $theme-color;
}

.page-p{
    font-family: Poppins, arial;
    font-size: 20px;
    margin: 1rem 0;
    padding: 1rem 0;
}

.heading-divider{
    background: radial-gradient(circle, rgba(160,150,0,1) 0%, rgba(160,150,0,0) 100%);
    height: 3px;
    margin: 5px auto;
    width: 100%;
    margin-bottom: 30px;
}

.flex-img{
    width: 100%;
    height: auto;
}

.img-link{
    transition: $theme-transition;
    will-change: transform;
    &:hover{
        transform: scale(1.05);
    }
}

.text-theme-highlight{
    color: $theme-color;
}
