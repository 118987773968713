@use '../../theme' as *;

.socialmedia-links-container{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .hidden-link-text {
        position: absolute;
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
        clip-path: inset(0px 0px 99.9% 99.9%);
        overflow: hidden;
        height: 1px;
        width: 1px;
        padding: 0;
        border: 0;
        top: 50%;
    }

    a{
        display: block;
        width: 50px;
        height: 50px;
        background-size: 100%;
        background-repeat: no-repeat;
        will-change: transform;
        transition: $theme-transition;

        &:hover{
            transform: scale(1.2) translateY(-5px);
        }

        &.twitter{
            background-image: url('./assets/twitter-app-icon.png');
        }
        &.linkedin{
            background-image: url('./assets/linkedin-app-icon.png');
        }
        &.instagram{
            background-image: url('./assets/instagram-color-icon.png');
        }
        &.facebook{
            background-image: url('./assets/facebook-app-icon.png');
        }
        &.youtube{
            background-image: url('./assets/youtube-app-icon.png');
        }
    }
}
