/* Styles for Footer Component */
@use '../../theme' as *;

.pg-footer {
    position: relative;
    font-family: "Poppins";
    width: 100%;

    .footer{
        margin-top: 3rem;
        background-color: $theme-black;
        color: #fff;

        a {
            color: #fff;
            &:hover{
                color: $theme-color;
            }

            .hidden-link-text {
                position: absolute;
                clip: rect(1px 1px 1px 1px);
                clip: rect(1px, 1px, 1px, 1px);
                -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
                clip-path: inset(0px 0px 99.9% 99.9%);
                overflow: hidden;
                height: 1px;
                width: 1px;
                padding: 0;
                border: 0;
                top: 50%;
            }
        }

        .footer-wave-svg {
            height: 30px;
            width: 100%;
            margin-top: -1px;

            @media screen and (min-width: 760px){
                height: 50px;
            }

            path {
                fill: $theme-grey;
            }
        }

        .footer-content {
            position: relative;
            margin: auto;
            padding: 2rem;
            padding-bottom: 50px;
            max-width: $theme-max-width;

            .footer-content-columns{
                padding: 1rem;
                display: grid;
                grid-template: 1fr / repeat(auto-fit, minmax(200px, 1fr));
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 2rem;

                .footer-content-column{
                    .menu{
                        margin: 0;
                        padding: 0;
                        list-style-type: none;
                        li.menu-item{
                            margin: 0.25rem;
                        }
                    }

                    &.snp-container{
                            justify-self: flex-end;
                        @media screen and (max-width: 515px){
                            justify-self: center;
                        }
                        .snp-heading{
                            text-align: center;
                        }
                        .sponsors-container{
                            margin: 2rem 0;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            gap: 2rem;

                            img{
                                display: block;
                                width: 100%;
                                max-width: 200px;
                            }
                        }
                    }
                }

            }
        }

        .socialmedia-links-container{
            max-width: $theme-max-width;
            margin: auto;
            padding: 2rem;
            display: flex;
            justify-content: flex-end;
            @media screen and (max-width: 515px){
                justify-content: center;
            }
        }

        .footer-copyright {
            padding: 1rem;
            text-align: center;
        }
    }
}
