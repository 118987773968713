/* Sponsorships and Partners Component Styles */
@use "../../theme" as *;

.snp-heading {
    text-align: center;
}

.sponsors-container{
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;

    img{
        display: block;
        max-width: 250px;
        width: 100%;
        will-change: transform;
        transition: $theme-transition;

        &:hover{
            transform: scale(1.1);
        }
    }
}
