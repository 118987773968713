/* SideBar Component Styles */
@use '../../theme' as *;

@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css);

.btn-primary {
    position: relative;
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    color: $theme-black;
    font-size: 45px;
    z-index:1;
    transition: $theme-transition;

    &:hover{
        cursor: pointer;
        color: $theme-color;
    }

    &.open-menu-btn{
        background-color: $theme-black;
        color: $theme-white;
        @media screen and (min-width: 860px){
            display: none;
        }
        &:hover{
            color: $theme-color;
        }
    }

    &.close-menu-btn{
    }
}

.sidebar {
    width: 280px;
    min-height: 100vh;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    background-color: #fff;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 4;
    transition: $theme-transition;

    &.active{
        right: 0;
    }

    .sd-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
    }

    .sd-body {
        max-height: calc(100vh - 67px);
        overflow-x: hidden;

        ul.menu{
            display: inline-block;
            width: 100%;
            margin-bottom: 0;
            padding: 0;

            li.menu-item{
                list-style: none;
                margin-bottom: 8px;

                &:hover{
                    background-color: rgb(160, 150, 0);
                    transition: $theme-transition;
                }

                a.menu-item-link{
                    width: 100%;
                    padding: 10px 16px;
                    color: black;
                    cursor: pointer;
                    text-decoration: none;
                    display: flex;
                    align-items: center;

                    &:hover{
                        color: $theme-white;
                    }
                }
            }
        }
    }
}


.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;

    &.active {
        opacity: 1;
        visibility: visible;
    }
}
