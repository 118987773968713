@use '../../theme' as *;

.header-container {
    width: 100%;
    background-color: $theme-white;
    position: relative;

    .main-header-container {
        position: relative;
        max-width: $theme-max-width;
        margin: auto;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        @media screen and (max-width: 1024px){
            gap: 3rem;
        }
        @media screen and (max-width: 450px){
            flex-wrap: wrap;
            gap: 1rem;
        }

        &::before{
            z-index: 1;
            content: "";
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url(./assets/header.png);
            background-size: cover;
            background-position: center;
            filter: grayscale(0.3) opacity(0.2);
        }

        #logo-link {
            position: relative;
            z-index: 2;
            display: flex;

            img {
                width: 100px;
                height: auto;
            }
        }

        #header-title-container{
            display: flex;
            flex-flow: column wrap;
            align-items: center;
            position: relative;
            z-index: 2;

            #title-header {
                font-weight: 1000;
                font-size: 60px;
                font-family: Teko;
                color: $theme-color;

                
                @media screen and (max-width: 700px){
                    font-size: 35px;
                }

                @media screen and (max-width: 200px){
                    display: none;
                }
            }

            .socialmedia-links-container{
                margin-top: -0.5rem;
                @media screen and (max-width: 700px){
                    margin-top: 0;
                }

                .socialmedia-link{
                    width: 30px;
                    height: 30px;
                }

            }
        }
    }
}


.header-menu {
    position: relative;
    z-index: 2;
    width: 100%;
    background-color: $theme-black;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 860px){
        display: none;
    }

    nav.menu-container{
        height: 100%;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;

        ul.menu{
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            max-width: $theme-max-width;
            margin: 0;
            gap: 20px;
            padding: 15px 0px;

            li.menu-item{
                list-style-type: none;

                a.menu-item-link{
                    color: $theme-white;
                    font-weight: 700;
                    transition: $theme-transition;

                    &:hover{
                        color: $theme-color;
                    }
                    @media screen and (max-width: 1000px){
                        font-size: 12px;
                    }
                    @media screen and (max-width: 860px){
                        font-size: 10px;
                    }
                }
            }
        }
    }
}
